<template>
  <div class="radio_container required err_message_box">
    <p>
      <span class="txt-red txt-regular" v-if="item.required">* </span>
      {{ item.label }}
    </p>
    <span class="err_message txt-red small">{{errorMsg}}</span>
    <label
      v-for="(option, index) in item.options"
      :key="`option_${index}`"
      class="p"
    >
      <input
        :id="option.value"
        :class="{ readonly: readonly }"
        :checked="readonly"
        type="radio"
        :name="item.name"
        :value="option.value"
        :error="error"
        @change="test()"
        @input="$emit('input', $event.target.value)"
      >{{ option.name }}
      <div class="checkmark"></div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'FormRadio',
  props: [
    'value',
    'item',
    'readonly',
  ],
  data() {
    return {
      errorMsg: '',
    };
  },
  methods: {
    test() {
      this.errorMsg = !this.value ? '請至少選擇一項' : '';
    },
  },
  computed: {
    error() {
      if (this.errorMsg !== '') {
        return true;
      }
      return false;
    },
  },
  watch: {
    value() {
      if (!this.value) {
        this.errorMsg = '請至少選擇一項';
      }
    },
  },
};
</script>
